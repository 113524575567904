const readBackgroundURL = (input) => {
  const imagePreview = document.querySelector('#background_img_prev');
  const avatarImage = document.querySelector('#background-remove');
  imagePreview.style.display = "";
  if (document.querySelector('#background-remove')) {
    avatarImage.style.display ="none";
  }
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $('#background_img_prev')
        .attr('src', e.target.result);
    };

    reader.readAsDataURL(input.files[0]);
  }
}

export { readBackgroundURL };
